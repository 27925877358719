<template>
	<w-layout fill-height pt-5 px-5 scroll-y>
		<v-flex>
			<v-form ref="form" v-model="formValid">
				<v-flex xs12>
					<template v-for="(setting, key) in originalSettings">
						<v-switch
							v-if="setting.type === 'boolean'"
							:key="settings[key].key"
							v-model="settings[key].value"
							color="primary"
							:label="$t('nps_survey.settings.fields.' + settings[key].key + '.label') + (fieldIsRequired(setting) ? ' *' : '')"
							:required="fieldIsRequired(setting)"
							:disabled="fieldIsDisabled(setting)"
						></v-switch>
						
						<v-text-field
							v-if="setting.type === 'text'"
							:key="settings[key].key"
							v-model="settings[key].value"
							:label="$t('nps_survey.settings.fields.' + settings[key].key + '.label') + (fieldIsRequired(setting) ? ' *' : '')"
							:required="fieldIsRequired(setting)"
							:disabled="fieldIsDisabled(setting)"
							:rules="fieldRules(setting)"
							:type="setting.type"
							color="primary"
						></v-text-field>
						<w-number-input
							v-if="setting.type === 'number'"
							:key="settings[key].key"
							v-model="settings[key].value"
							:label="$t('nps_survey.settings.fields.' + settings[key].key + '.label')"
							:required="fieldIsRequired(setting)"
							:disabled="fieldIsDisabled(setting)"
							:rules="fieldRules(setting)"
							:type="setting.type"
							color="primary"
							:min="0"
						/>
						<v-textarea
							v-if="setting.type === 'json'"
							:key="settings[key].key"
							v-model="settings[key].value"
							:required="fieldIsRequired(setting)"
							:disabled="fieldIsDisabled(setting)"
							:rules="fieldRules(setting)"
							:auto-grow="fieldIsAutoGrow(setting)"
							:label="$t('nps_survey.settings.fields.' + settings[key].key + '.label') + (fieldIsRequired(setting) ? ' *' : '')"
							color="primary"
						></v-textarea>
					</template>
				</v-flex>
				<v-flex v-if="settings !== null" text-xs-center pb-4>
					<v-btn color="primary" :disabled="!formValid" @click="save()">
						{{ $t('actions.validate') }}
					</v-btn>
				</v-flex>
			</v-form>
		</v-flex>
	</w-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'SuperAdminNPSSurveyManager',
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			loading: false,
			settings: null,
			originalSettings: [],
			formValid: false,
			rules: {
				required: v => (v && v.length > 0) || this.$t('required'),
				numeric: v => (v && !isNaN(parseFloat(v))) || this.$t('number'),
				json: v => {
					try {
						return (
							(
								v &&
								(str => {
									if (typeof str !== 'string') {
										return false
									}
									try {
										JSON.parse(str)
									} catch (e) {
										return false
									}
									return true
								})
							)(v) || this.$t('nps_survey.settings.rules.invalid-json')
						)
					} catch (e) {
						return this.$t('nps_survey.settings.rules.invalid-json')
					}
				}
			}
		}
	},
	computed: {},
	watch: {
		loading: {
			handler: function () {
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, this.loading)
			}
		}
	},
	created: function () {
		this.loading = true
		this.load()
	},
	methods: {
		sanitise: function () {
			this.settings = this.settings.map(setting => {
				if (setting.type.toLowerCase() === 'json') {
					setting.value = JSON.stringify(JSON.parse(setting.value), null, '\t')
				}
				if (setting.type.toLowerCase() === 'boolean') {
					setting.value = setting.value === '1' || setting.value === 1 || setting.value.toLowerCase() === 'true'
				}

				return setting
			})
		},
		/**
		 * @return {Promise<void>}
		 */
		load: async function () {
			this.settings = await this.service.getNPSSurveySettings()
			this.originalSettings = JSON.parse(JSON.stringify(this.settings))

			this.sanitise()
			this.loading = false
		},
		/**
		 * @return {Promise<void>}
		 */
		save: async function () {
			this.loading = true
			this.service
				.updateNPSSurveySettings(
					this.cloneObject(this.settings).map(setting => {
						let value = setting.value

						switch (setting.type) {
							case 'number':
								value = parseInt(value)
								break
							case 'json':
								value = JSON.stringify(JSON.parse(value))
								break
							default:
						}
						return {
							id: setting.key,
							value: value
						}
					})
				)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('nps_survey.settings.save_success'))
				})
				.catch(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('nps_survey.settings.save_error'))
				})
				.finally(() => {
					this.loading = false
				})
		},
		/**
		 * @param {Setting} field
		 * @return {boolean}
		 */
		fieldIsRequired: function (field) {
			return (
				field.attributes?.required === true || field.attributes?.required === 'true' || field.attributes?.required === '1' || field.attributes?.required === 1
			)
		},
		/**
		 * @param {Setting} field
		 * @return {boolean}
		 */
		fieldIsDisabled: function (field) {
			return (
				field.attributes?.disabled === true || field.attributes?.disabled === 'true' || field.attributes?.disabled === '1' || field.attributes?.disabled === 1
			)
		},
		/**
		 * @param {Setting} field
		 * @return {boolean}
		 */
		fieldIsResisable: function (field) {
			return (
				field.attributes?.resizable === true ||
				field.attributes?.resizable === 'true' ||
				field.attributes?.resizable === '1' ||
				field.attributes?.resizable === 1
			)
		},
		/**
		 * @param {Setting} field
		 * @return {boolean}
		 */
		fieldIsAutoGrow: function (field) {
			return !(
				field.attributes?.auto_grow === false ||
				field.attributes?.auto_grow === 'false' ||
				field.attributes?.auto_grow === '0' ||
				field.attributes?.auto_grow === 0
			)
		},
		/**
		 * @param {Setting} field
		 * @return {*[]}
		 */
		fieldRules: function (field) {
			let rules = []

			if (Array.isArray(field.attributes?.rules)) {
				field.attributes?.rules.forEach(rule => (typeof this.rules[rule] !== undefined ? rules.push(this.rules[rule]) : null))
			}

			return rules
		}
	}
}
</script>
